<div id="parent" class="badge-connection d-flex justify-content-center">
  <div class="badge-connection_left-side"></div>
  <div class="badge-connection_right-side"></div>

  <div class="badge-connection_overlay" *ngIf="isManualBadge">
    <input id="password-input" type="password"
           placeholder="{{'LOGIN.BADGE.PLACEHOLDER' | translate}}"
           class="password-input form-control"
           [(ngModel)]="badgeCode"
           autofocus/>
    <button id="submit-btn" class="submit-btn btn btn-primary" [disabled]="isButtonDisabled"
            (click)="onSubmit()">{{'LOGIN.CONNECT' | translate}}</button>
  </div>
</div>
