const country = (localStorage.getItem('country') ? localStorage.getItem('country') : '').toLowerCase();
export const HOST = `https://api.demo.${country}.mobime.transdev.com`;
const AUTH_HOST = getAuthHost();
const APP_ID = getAppID();

export const HCAPTCHA_SITE_KEY = getHCatpchaSiteKey();
export const HCAPTCHA_SECRET_KEY = getHCatpchaSecretKey();
export const WEBSOCKET_HOST = getWebSocket();


const CALLBACK_URL = `https://demo.${country}.mobime.transdev.com`;

export const AUTH_URL = {
  AUTHORIZE: `${AUTH_HOST}/oauth2/authorize?client_id=${APP_ID}&response_type=token` +
             `&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${CALLBACK_URL}`,
  LOGOUT: `${AUTH_HOST}/logout?client_id=${APP_ID}&response_type=token` +
             `&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${CALLBACK_URL}`,
};
export const mobiAdminDomaine = `https://demo.${country}.mobiservices.transdev.net`;

export const environment = {
  appVersion: require('../../package.json').version + '-demo',
  production: true
};

export const CALENDAR_PROPS = {
  NBR_DAYS: 30,
  PLANNING_DAYS: 365
};

export const GIT_URLS = {
  REPO_MOBINEXT_COMMIT: 'https://github.com/transdev-corp/MobiNext/commit/'
};

function getAppID() {
  switch (country) {
    case 'fr':
      return '36svbhi6bqvhnpn7gtn2l62pt7';
    case 'ca':
      return 'N/A';
    default:
      return 'N/A';
  }
}

function getAuthHost() {
  switch (country) {
    case 'fr':
      return 'https://demo-mobime-transdev.auth.eu-west-1.amazoncognito.com';
    case 'ca':
      return 'N/A';
    default:
      return 'N/A';
  }
}

function getWebSocket() {
  switch (country) {
    case 'fr':
      return 'wss://wmv9y1nc3i.execute-api.eu-west-1.amazonaws.com/Prod';
    case 'ca':
      return 'wss://N/A';
    default:
      return 'wss://N/A';
  }
}
function getHCatpchaSiteKey() {
  switch (country) {
    case 'fr':
      return 'ee9e505e-72d2-4b8a-b042-83aef508d3e6';
    case 'ca':
      return 'fdcc9804-0372-4d94-879a-35369366acf6';
    case 'us':
      return '27e88bde-6cc2-49c2-a7a6-3a9abe8e317a';
    case 'au':
      return '16648b76-cad8-49be-a7c8-0106ab8e4e8c';
    case 'ie':
      return 'e7b89710-b2d0-415e-b378-d6fe8fb45f68';
    default:
      return 'e7b89710-b2d0-415e-b378-d6fe8fb45f68';
  }
}

function getHCatpchaSecretKey() {
  switch (country) {
    case 'fr':
      return 'ES_c001991f55694a41aff6e1c2bf510830';
    case 'ca':
      return 'ES_ad47f071a5ad453492fc64e1bd1932d8';
    case 'us':
      return 'ES_bbd6adeb94a54b36b0d15487a5134019';
    case 'au':
      return 'ES_863cd2331eeb4c05a46b686cdcd0cb27';
    case 'ie':
      return 'ES_7462ad5c81f34b0fbe10c7ff5bbf26a5';
    default:
      return 'ES_7462ad5c81f34b0fbe10c7ff5bbf26a5';
  }
}
