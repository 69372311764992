<div
  class="px-3 d-flex align-items-center justify-content-center"
  *ngIf="show"
  [ngClass]="{
    'snackbar-wrap': true,
    'centered': centered === true,
    'default': centered === false,
    'success': type === 'success',
    'error': type === 'error',
    'info': type === 'info'
  }">
  <div class="h-100 w-100 d-flex justify-content-between pb-2">
    <div class="d-flex align-items-center ps-4">
      <div
        class="icon icon-24 h-100"
        [ngClass]="{
          'icon-checked': type === 'success',
          'icon-cross': type === 'error',
          'icon-info': type === 'info'
        }">
      </div>
      <p class="snackbar-text ubuntu-regular font-size-14 ps-3">
        {{ message | translate }}
      </p>
    </div>
    <div
      class="icon icon-12 icon-leave pointer"
      (click)="hideSnackBar()">
    </div>
  </div>
</div>
