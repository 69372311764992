import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BadgeConnectionComponent} from './badge-connection.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [BadgeConnectionComponent]
})
export class BadgeConnectionModule {
}
